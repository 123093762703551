import type { InputHTMLAttributes } from 'react';
import React, { forwardRef, useEffect, useRef } from 'react';

export interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  helpText?: string;
  errorText?: string;
  icon?: JSX.Element | React.ReactNode;
  onIconClick?: () => void;
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      label,
      helpText,
      errorText,
      icon,
      onIconClick,
      className,
      value,
      onChange,
      ...props
    }: InputFieldProps,
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (errorText && containerRef.current) {
        containerRef.current.scrollIntoView({
          block: 'center',
          inline: 'center',
        });
      }
    }, [errorText]);

    return (
      <div ref={containerRef} className="flex w-full flex-col gap-1">
        {label && (
          <div className="w-full">
            <label className="text-sm font-medium text-typo-200">{label}</label>
          </div>
        )}
        <div className="relative flex w-full items-center justify-end">
          {icon && (
            <div
              className="absolute my-auto p-2 text-sm text-gray-500 md:text-base md:hover:cursor-pointer"
              onClick={onIconClick}
            >
              {icon}
            </div>
          )}
          <input
            ref={ref}
            className={`w-full rounded-lg border px-3 py-[10px] font-medium text-gray-700 outline-none placeholder:text-typo-50 disabled:border-gray-300 disabled:bg-gray-50 disabled:text-gray-500 ${
              icon != null ? ' pr-10 ' : ''
            } ${className}`}
            spellCheck={false}
            value={value ?? ''}
            onChange={onChange != null ? onChange : () => undefined}
            {...props}
          />
        </div>
        {helpText && (
          <span className="self-start text-xs text-typo-100 md:text-sm">
            {helpText}
          </span>
        )}
        {errorText && (
          <span className="self-start text-xs text-red-600 md:text-sm">
            {errorText}
          </span>
        )}
      </div>
    );
  }
);

export default InputField;
