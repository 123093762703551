import { create } from 'zustand';

import type { User } from '@/backend/directusSchema';

import CustomAuth from './customAuth';

export interface UserState {
  currentUser?: User;
  fetchUser: () => void;
  clearCurrentUser: () => void;
}

const useUserService = create<UserState>()((set) => ({
  fetchUser: () => {
    CustomAuth.userProfile().then((res) => {
      set((state) => ({ ...state, currentUser: res }));
    });
  },
  clearCurrentUser: () => {
    set((state) => ({ ...state, currentUser: undefined }));
  },
}));

export default useUserService;
