import {
  Bell02,
  Building07,
  Home01,
  Home03,
  HomeLine,
  Settings01,
  ShoppingCart01,
  User01,
  UserPlus01,
  Users01,
} from '@untitled-ui/icons-react';

import BookingHistory from '@/components/user/BookingHistory';
import ChangePassword from '@/components/user/ChangePassword';
import DepositHistory from '@/components/user/DepositHistory';
import ReservationHistory from '@/components/user/ReservationHistory';
import UserProfile from '@/components/user/UserProfile';

export const defaultAvatar = 'bac7c42a-dfc7-40b0-b671-a142e10eea27';

export type NavigatorItem = {
  name: string;
  headerName?: string;
  icon: React.ReactElement;
  showHeader: boolean;
  path: string;
};

export const navigatorItems: NavigatorItem[] = [
  {
    name: 'Trang chủ',
    icon: <Home01 />,
    showHeader: true,
    path: '/',
  },
  {
    name: 'Quy hoạch',
    icon: <Home03 />,
    showHeader: true,
    path: '/master-plan',
  },
  {
    name: 'Giỏ hàng',
    icon: <ShoppingCart01 />,
    showHeader: true,
    path: '/apartments',
  },
  {
    name: 'DS dự án',
    headerName: 'Danh sách sự án',
    icon: <Building07 />,
    showHeader: true,
    path: '/advertising-projects',
  },
  {
    name: 'Thông báo',
    icon: <Bell02 />,
    showHeader: false,
    path: '/notifications',
  },
];

export interface UserTab {
  path: string;
  title: string;
  icon: React.ReactElement;
  tab: React.ReactElement;
}

export const userTabs: UserTab[] = [
  {
    path: 'profile',
    title: 'Thông tin cá nhân',
    icon: <User01 />,
    tab: <UserProfile />,
  },
  {
    path: 'change-password',
    title: 'Đổi mật khẩu',
    icon: <Settings01 />,
    tab: <ChangePassword />,
  },
  {
    path: 'bookings',
    title: 'Danh sách đặt lịch',
    icon: <UserPlus01 />,
    tab: <BookingHistory />,
  },
  {
    path: 'reservations',
    title: 'Danh sách giữ chỗ',
    icon: <Users01 />,
    tab: <ReservationHistory />,
  },
  {
    path: 'deposits',
    title: 'Danh sách đặt cọc',
    icon: <HomeLine />,
    tab: <DepositHistory />,
  },
];
