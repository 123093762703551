import type { HTMLProps } from 'react';
import { create } from 'zustand';

export type INotiVariant = {
  [key in 'success' | 'error']: {
    icon: JSX.Element;
    title: string;
    color: string;
  };
};

export interface NotiProps extends HTMLProps<HTMLDivElement> {
  variant: keyof INotiVariant;
  message: string;
  isOpen: boolean;
}

export interface NotiState {
  notiData: NotiProps;
  open: (noti: NotiProps) => void;
  close: () => void;
}

const useNotiService = create<NotiState>()((set, get) => ({
  notiData: { variant: 'success', message: '', isOpen: false },
  open: (props) => {
    get();
    set(() => {
      const newNoti = props;
      return { notiData: newNoti };
    });
  },
  close: () => {
    set((state) => {
      const newData = { ...state.notiData, isOpen: false };
      return { notiData: newData };
    });
  },
}));

export default useNotiService;
