import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { NextSeoProps } from 'next-seo';
import { NextSeo } from 'next-seo';
import { type HTMLProps, type ReactNode, useEffect } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import ModalView from '@/components/common/ModalView';
import Notification from '@/components/common/Notification';
import Footer from '@/components/footer/Footer';
import Header from '@/components/headers/Header';
import { type NavigatorItem, navigatorItems } from '@/config/constants';
import runOneSignal from '@/utils/runOneSignal';

declare global {
  const ZaloSocialSDK: {
    reload: () => void;
  };
}

export interface ILandingPageTemplateProps extends HTMLProps<HTMLDivElement> {
  seo?: NextSeoProps;
  customHeader?: ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideMoveToTop?: boolean;
  hideZaloWidget?: boolean;
  hideNavigator?: boolean;
}

const NavigateItem = ({ navigateItem }: { navigateItem: NavigatorItem }) => {
  const router = useRouter();
  return (
    <Link
      href={navigateItem.path}
      className={`flex cursor-pointer flex-col items-center gap-1 border-t-[3px] py-3 hover:bg-slate-100 ${
        (navigateItem.path === '/' && router.asPath === '/') ||
        (navigateItem.path !== '/' &&
          router.asPath.startsWith(navigateItem.path))
          ? 'border-primary-700 text-primary-700'
          : 'border-transparent text-typo-100'
      }`}
      shallow
    >
      <div>{navigateItem.icon}</div>
      <span className="text-xxs sm:text-sm">{navigateItem.name}</span>
    </Link>
  );
};

const Navigator = () => {
  return (
    <div className="fixed bottom-0 left-0 w-full bg-white shadow-[0_-4px_8px_0px_rgba(0,0,0,0.08)] md:hidden">
      <div className="grid grid-cols-5 px-4">
        {navigatorItems.map((item: NavigatorItem, i) => (
          <NavigateItem key={i} navigateItem={item} />
        ))}
      </div>
    </div>
  );
};

export default function LandingPageTemplate({
  seo,
  customHeader,
  hideHeader,
  hideFooter,
  hideNavigator,
  // hideZaloWidget,
  children,
}: ILandingPageTemplateProps) {
  const router = useRouter();
  const session = useSessionContext();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(session);
    }
  }, [session]);

  useEffect(() => {
    runOneSignal();
  }, []);

  return (
    <>
      <Head>
        <meta charSet="UTF-8" key="charset" />
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0"
          key="viewport"
        />
        <link
          rel="apple-touch-icon"
          href={`${router.basePath}/apple-touch-icon.png`}
          key="apple"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="512x512"
          href={`${router.basePath}/android-chrome-512x512.png`}
          key="icon512"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`${router.basePath}/android-chrome-192x192.png`}
          key="icon192"
        />
        <link
          rel="icon"
          href={`${router.basePath}/favicon.ico`}
          key="favicon"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#0C111D" />
      </Head>
      <NextSeo
        {...{
          ...{
            ...seo,
            openGraph: { ...seo?.openGraph, siteName: 'Winland' },
          },
          defaultTitle: 'Winland',
          // languageAlternates: [
          //   {
          //     hrefLang: 'en',
          //     href: `${process.env.NEXT_PUBLIC_BASE_URL}/en/`,
          //   },
          //   {
          //     hrefLang: 'vi',
          //     href: `${process.env.NEXT_PUBLIC_BASE_URL}/vi/`,
          //   },
          // ],
          additionalMetaTags: [
            {
              name: 'mobile-web-app-capable',
              content: 'yes',
            },
            {
              name: 'apple-mobile-web-app-capable',
              content: 'yes',
            },
            // {
            //   name: 'keywords',
            //   content:
            //     'Rizer, Rizers, Rizer soft, Rizers soft, Rizerssoft, Rizersoft, business, solution, solutions, technologies, technology, mobile app, web app, app development, consulting, digital, digital transform, digital transformation, vietnam, viet nam, vietnamese, ho chi minh, giai phap, dich vu, chuyen doi so, outsource, outsourcing, tu van giai phap',
            // },
            {
              name: 'google',
              content: 'nositelinkssearchbox',
              keyOverride: 'sitelinks',
            },
            {
              name: 'google',
              content: 'notranslate',
              keyOverride: 'notranslate',
            },
          ],
          twitter: {
            cardType: 'summary_large_image',
          },
        }}
      />
      <main className="box-border w-full bg-transparent max-md:select-none">
        {!hideHeader && <>{customHeader || <Header />}</>}
        <div className="flex flex-col">{children}</div>
        {!hideFooter && <Footer />}
        {!hideNavigator && <Navigator />}
        <ModalView />
        <Notification />
      </main>
    </>
  );
}
