import _ from 'lodash';
import { useTranslation } from 'next-i18next';
import Form, { Field } from 'rc-field-form';
import { useRef, useState } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { EmptyAvatar } from '@/assets';
import CustomAuth from '@/services/customAuth';
import useModalService from '@/services/modalService';
import useUserService from '@/services/userService';
import { defaultValidateMessages } from '@/utils/defaultValidateMessages';
import { setNativeValue } from '@/utils/Utils';

import CustomImage from '../common/CustomImage';
import IconButton from '../common/IconButton';
import InputField from '../form/InputField';

const UserProfile = () => {
  const { t } = useTranslation('common');
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<any>({});
  const [form] = Form.useForm();
  const { open } = useModalService();
  const { currentUser, fetchUser } = useUserService((state) => ({
    currentUser: state.currentUser,
    fetchUser: state.fetchUser,
  }));
  const session = useSessionContext();
  const uploadImageInputRef = useRef<HTMLInputElement>(null);
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const avatarWatch = Form.useWatch('avatar', form);

  const onSubmit = async (values: any) => {
    setLoading(true);
    CustomAuth.updateProfile({
      fullname: values.fullname,
      avatar:
        values.avatar && uploadImageInputRef.current?.files?.[0] != null
          ? uploadImageInputRef.current.files[0]
          : undefined,
    })
      .then((res) => {
        if (res.status === 'OK') {
          open({
            key: 'updateProfileSuccess',
            header: {
              title: 'Cập nhật thông tin thành công',
            },
            content: <>Bạn vừa thực hiện cập nhật thông tin thành công</>,
            className: 'py-5 my-auto !px-5 mx-auto',
            containerClassName: '!h-min !w-max',
          });

          fetchUser();
        } else {
          open({
            key: 'updateProfileFailed',
            header: {
              title: 'Cập nhật thông tin thất bại',
            },
            content: <>{`${t(`authErrors.${res.status}`)}`}</>,
            className: 'py-5 my-auto !px-5 mx-auto',
            containerClassName: '!h-min !w-max',
          });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="flex w-full flex-col">
      {!session.loading && session.doesSessionExist && currentUser != null && (
        <Form
          form={form}
          initialValues={{
            fullname: currentUser.name,
            avatar:
              currentUser.avatar != null
                ? `${currentUser.avatar}?width=96`
                : undefined,
          }}
          onFinish={onSubmit}
          validateMessages={defaultValidateMessages}
          onFinishFailed={(errors) => {
            setFieldErrors((oldData: any) => {
              return errors.errorFields.reduce(
                (a, v) => {
                  _.set(a, v.name.join('.'), v.errors?.[0]);
                  return a;
                },
                { ...oldData }
              );
            });
          }}
          onFieldsChange={(changedFields) => {
            setFieldErrors((oldData: any) => {
              return changedFields.reduce(
                (a, v) => {
                  const key = Array.isArray(v.name) ? v.name.join('.') : v.name;
                  _.set(a, key, undefined);
                  return a;
                },
                { ...oldData }
              );
            });
          }}
        >
          <div className="mb-5 flex w-full flex-row items-center justify-between">
            <span className="font-semibold text-typo-300 sm:text-lg">
              Thông tin cá nhân
            </span>
            <span>
              <IconButton
                label="Cập nhật"
                disableTranslate
                disabled={loading}
              />
            </span>
          </div>
          <div className="flex w-full flex-col items-center gap-x-8 border-t py-5 md:flex-row">
            <span className="shrink-0 self-start text-sm font-medium text-gray-700 md:min-w-[120px]">
              Ảnh đại diện
            </span>
            <div className="flex w-full justify-between md:mx-auto md:max-w-lg">
              <CustomImage
                src={avatarWatch}
                className="size-16 shrink-0 rounded-full border"
                alt="Edit Avatar"
                fallback={EmptyAvatar}
              />
              <div className="flex shrink-0 flex-row gap-1 self-start">
                <IconButton
                  label="Xóa"
                  variant="secondaryTransparent"
                  type="button"
                  disableTranslate
                  onClick={() => {
                    setNativeValue(avatarInputRef.current, '');
                    avatarInputRef.current?.dispatchEvent(
                      new Event('input', { bubbles: true })
                    );
                  }}
                />
                <IconButton
                  label="Tải ảnh lên"
                  variant="primaryTransparent"
                  type="button"
                  disableTranslate
                  onClick={() => {
                    uploadImageInputRef.current?.click();
                  }}
                />
              </div>
              <div className="hidden">
                <input
                  ref={uploadImageInputRef}
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  className="hidden"
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      if (!e.target.files[0].type.startsWith('image/')) {
                        return;
                      }
                      const reader = new FileReader();
                      reader.onload = (event) => {
                        setNativeValue(
                          avatarInputRef.current,
                          event.target?.result
                        );
                        avatarInputRef.current?.dispatchEvent(
                          new Event('input', { bubbles: true })
                        );
                      };
                      reader.readAsDataURL(e.target.files[0]);
                    }
                  }}
                />
                <Field
                  name="avatar"
                  messageVariables={{ label: 'Ảnh đại diện' }}
                >
                  <InputField
                    errorText={fieldErrors?.attributes?.name}
                    ref={avatarInputRef}
                  />
                </Field>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col items-center gap-x-8 border-t py-5 md:flex-row">
            <span className="shrink-0 self-start text-sm font-medium text-gray-700 md:min-w-[120px]">
              Tên hiển thị
            </span>
            <div className="flex w-full items-center justify-center md:mx-auto md:max-w-lg">
              <Field
                name="fullname"
                messageVariables={{ label: 'Tên hiển thị' }}
                rules={[
                  {
                    required: true,
                    type: 'string',
                    whitespace: true,
                    max: 50,
                  },
                ]}
              >
                <InputField errorText={fieldErrors?.attributes?.name} />
              </Field>
            </div>
          </div>
          <div className="flex w-full flex-col items-center gap-x-8 border-t py-5 md:flex-row">
            <span className="shrink-0 self-start text-sm font-medium text-gray-700  md:min-w-[120px]">
              Số điện thoại
            </span>
            <div className="flex w-full items-center justify-center md:mx-auto md:max-w-lg">
              <InputField
                disabled
                value={currentUser.phone_number}
                onChange={() => undefined}
              />
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export default UserProfile;
