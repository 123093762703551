import axios from 'axios';

import type { DepositInput, FilterDepositInput } from '@/@types/common';

const createDeposit = (input: DepositInput) => {
  return axios.post('/api/deposits/create', input).then((res) => res.data);
};

const filterDeposits = (input: FilterDepositInput) => {
  return axios
    .get('/api/deposits', {
      params: {
        apartmentCode: input.apartmentCode,
        fromDate: input.fromDate,
        toDate: input.toDate,
        page: input.page,
        size: input.size,
      },
    })
    .then((res) => res.data);
};

const getDepositById = (depositId: string) => {
  return axios.get(`/api/deposits/${depositId}`).then((res) => res.data);
};

const aggregate = () => {
  return axios.get(`/api/deposits/statistic`).then((res) => res.data);
};

const DepositApi = {
  createDeposit,
  filterDeposits,
  getDepositById,
  aggregate,
};

export default DepositApi;
