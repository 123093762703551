import type { HTMLProps } from 'react';
import React from 'react';

const variants = {
  red: {
    className: 'bg-red-600 text-white',
  },
  lightRed: {
    className: 'bg-red-400 text-white',
  },
  green: {
    className: 'bg-green-500 text-white',
  },
  blue: {
    className: 'bg-blue-600 text-white',
  },
  light: {
    className: 'bg-gray-200 text-slate-700',
  },
  dark: {
    className: 'bg-slate-800 text-white',
  },
  sky: {
    className: 'bg-blue-400 text-white',
  },
  yellow: {
    className: 'bg-yellow-500 text-white',
  },
  purple: {
    className: 'bg-purple-600 text-white',
  },
  roundedGreen: {
    className:
      '!py-1 !px-4 rounded-2xl bg-green-50 font-medium text-base md:text-lg text-green-700',
  },
  roundedIndigo: {
    className:
      '!py-1 !px-4 rounded-2xl bg-indigo-50 font-medium text-base md:text-lg text-indigo-700',
  },
  roundedOrange: {
    className:
      '!py-1 !px-4 rounded-2xl bg-orange-50 font-medium text-base md:text-lg text-orange-700',
  },
  roundedPink: {
    className:
      '!py-1 !px-4 rounded-2xl bg-pink-50 font-medium text-base md:text-lg text-pink-700',
  },
  roundedLightBlue: {
    className:
      '!py-1 !px-4 rounded-2xl bg-blue-50 font-medium text-base md:text-lg text-blue-700',
  },
  roundedYellow: {
    className:
      '!py-1 !px-4 rounded-2xl bg-yellow-50 font-medium text-base md:text-lg text-yellow-700',
  },
  roundedRed: {
    className:
      '!py-1 !px-4 rounded-2xl bg-red-50 font-medium text-base md:text-lg text-red-700',
  },
  roundedGray: {
    className:
      '!py-1 !px-4 rounded-2xl bg-gray-100 font-medium text-base md:text-lg text-gray-700',
  },
  roundedWarning: {
    className:
      '!py-1 !px-4 rounded-2xl bg-warning-50 border border-warning-200 font-medium text-base md:text-lg text-warning-600',
  },
  roundedSuccess: {
    className:
      '!py-1 !px-4 rounded-2xl bg-success-50 border border-success-200 font-medium text-base md:text-lg text-success-700',
  },
  roundedError: {
    className:
      '!py-1 !px-4 rounded-2xl bg-error-50 border border-error-200 font-medium text-base md:text-lg text-error-700',
  },
  roundedDenim: {
    className:
      '!py-1 !px-4 rounded-2xl bg-denim-100 border border-denim-200 font-medium text-base md:text-lg text-denim-700',
  },
};

export type BadgeVariant = keyof typeof variants;

export interface BadgeProps extends HTMLProps<HTMLSpanElement> {
  text: string;
  variant?: BadgeVariant;
}

export default function Badge({
  text,
  variant = 'light',
  className,
  ...props
}: BadgeProps) {
  return (
    <span
      {...props}
      className={`inline-block whitespace-nowrap rounded px-1 py-2.5 text-center align-baseline font-bold leading-none md:text-sm ${variants[variant].className} ${className}`}
    >
      {text}
    </span>
  );
}
