import axios from 'axios';

import type { FilterReservationInput, ReservationInput } from '@/@types/common';

const createReservation = (input: ReservationInput) => {
  return axios.post('/api/reservations/create', input).then((res) => res.data);
};

const filterReservations = (input: FilterReservationInput) => {
  return axios
    .get('/api/reservations', {
      params: {
        apartmentCode: input.apartmentCode,
        fromDate: input.fromDate,
        toDate: input.toDate,
        page: input.page,
        size: input.size,
      },
    })
    .then((res) => res.data);
};

const getReservationById = (reservationId: string) => {
  return axios
    .get(`/api/reservations/${reservationId}`)
    .then((res) => res.data);
};

const aggregate = () => {
  return axios.get(`/api/reservations/statistic`).then((res) => res.data);
};

const ReservationApi = {
  createReservation,
  filterReservations,
  getReservationById,
  aggregate,
};

export default ReservationApi;
