import { Eye, EyeOff } from '@untitled-ui/icons-react';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Form, { Field } from 'rc-field-form';
import { useState } from 'react';

import CustomAuth from '@/services/customAuth';
import useModalService from '@/services/modalService';

import IconButton from '../common/IconButton';
import InputField from '../form/InputField';

const ChangePassword = () => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { open } = useModalService();
  const [fieldErrors, setFieldErrors] = useState<any>({});
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const passwordWatch = Form.useWatch('newPassword', form);

  const onSubmit = (values: any) => {
    setLoading(true);
    CustomAuth.changePassword({
      currentPassword: values.password,
      newPassword: values.newPassword,
    })
      .then((res) => {
        if (res.status === 'OK') {
          open({
            key: 'changePasswordSuccess',
            header: {
              title: 'Thay đổi mật khẩu thành công',
            },
            content: <>Bạn vừa thực hiện thay đổi mật khẩu thành công</>,
            className: 'py-5 my-auto !px-5 mx-auto',
            containerClassName: '!h-min !w-max',
          });
          router.push('/', undefined, { shallow: true });
        } else {
          open({
            key: 'changePasswordFailed',
            header: {
              title: 'Thay đổi mật khẩu thất bại',
            },
            content: <>{`${t(`authErrors.${res.status}`)}`}</>,
            className: 'py-5 my-auto !px-5 mx-auto',
            containerClassName: '!h-min !w-max',
          });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="flex w-full flex-col">
      <Form
        form={form}
        onFinish={onSubmit}
        onFinishFailed={(errors) => {
          setFieldErrors((oldData: any) => {
            return errors.errorFields.reduce(
              (a, v) => {
                _.set(a, v.name.join('.'), v.errors?.[0]);
                return a;
              },
              { ...oldData }
            );
          });
        }}
        onFieldsChange={(changedFields) => {
          setFieldErrors((oldData: any) => {
            return changedFields.reduce(
              (a, v) => {
                const key = Array.isArray(v.name) ? v.name.join('.') : v.name;
                _.set(a, key, undefined);
                return a;
              },
              { ...oldData }
            );
          });
        }}
      >
        <div className="mb-5 flex w-full flex-row items-center justify-between">
          <span className="font-semibold text-typo-300 sm:text-lg">
            Đổi mật khẩu
          </span>
          <span>
            <IconButton label="Cập nhật" disableTranslate disabled={loading} />
          </span>
        </div>
        <div className="flex w-full flex-col items-center gap-x-8 border-t py-5 md:flex-row">
          <span className="shrink-0 self-start text-sm font-medium text-gray-700 md:min-w-[120px]">
            Mật khẩu hiện tại
          </span>
          <div className="flex w-full items-center justify-center md:mx-auto md:max-w-lg">
            <Field
              name="password"
              messageVariables={{ label: 'Mật khẩu hiện tại' }}
              rules={[
                {
                  pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
                  message:
                    'Mật khẩu không đúng định dạng. Mật khẩu phải có tối thiểu 8 ký tự, có ít nhất 1 chữ cái và 1 chữ số.',
                },
                {
                  required: true,
                  whitespace: true,
                  min: 8,
                },
              ]}
            >
              <InputField
                placeholder="Nhập mật khẩu hiện tại"
                autoComplete="off"
                autoSave="off"
                errorText={fieldErrors.password}
                type={showPassword ? 'text' : 'password'}
                icon={showPassword ? <EyeOff /> : <Eye />}
                onIconClick={() => setShowPassword((old) => !old)}
              />
            </Field>
          </div>
        </div>
        <div className="flex w-full flex-col items-center gap-x-8 border-t py-5 md:flex-row">
          <span className="shrink-0 self-start text-sm font-medium text-gray-700 md:min-w-[120px]">
            Mật khẩu mới
          </span>
          <div className="flex w-full items-center justify-center md:mx-auto md:max-w-lg">
            <Field
              name="newPassword"
              messageVariables={{ label: 'Mật khẩu mới' }}
              rules={[{ required: true, whitespace: true, min: 6 }]}
            >
              <InputField
                placeholder="Nhập mật khẩu mới"
                helpText="Mật khẩu phải có tối thiểu 8 ký tự, có ít nhất 1 chữ cái và 1 chữ số."
                autoComplete="off"
                autoSave="off"
                errorText={fieldErrors.newPassword}
                type={showPassword ? 'text' : 'password'}
                icon={showPassword ? <EyeOff /> : <Eye />}
                onIconClick={() => setShowPassword((old) => !old)}
              />
            </Field>
          </div>
        </div>
        <div className="flex w-full flex-col items-center gap-x-8 border-t py-5 md:flex-row">
          <span className="shrink-0 self-start text-sm font-medium text-gray-700  md:min-w-[120px]">
            Nhập lại
          </span>
          <div className="flex w-full items-center justify-center md:mx-auto md:max-w-lg">
            <Field
              name="confirmNewPassword"
              messageVariables={{ label: 'Nhập lại' }}
              rules={[
                {
                  validator: (_rule, value) => {
                    if (value !== passwordWatch) {
                      return Promise.reject(
                        new Error('Nhập lại mật khẩu mới không trùng khớp.')
                      );
                    }
                    return Promise.resolve();
                  },
                },
                { required: true, whitespace: true, min: 6 },
              ]}
            >
              <InputField
                placeholder="Nhập lại mật khẩu mới"
                autoComplete="off"
                autoSave="off"
                errorText={fieldErrors.confirmNewPassword}
                type={showPassword ? 'text' : 'password'}
                icon={showPassword ? <EyeOff /> : <Eye />}
                onIconClick={() => setShowPassword((old) => !old)}
              />
            </Field>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ChangePassword;
