import type { StaticImageData } from 'next/image';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { WinlandLogo } from '@/assets';

export interface HeaderLogoProps {
  customLogo?: string | StaticImageData;
}

export default function HeaderLogo({ customLogo }: HeaderLogoProps) {
  return (
    <Link
      href="/"
      scroll={false}
      className="flex flex-row items-center text-inherit"
      shallow
    >
      <Image
        src={customLogo ?? WinlandLogo}
        alt="logo"
        width={48}
        height={48}
        style={{ objectFit: 'cover', objectPosition: 'center' }}
        crossOrigin="anonymous"
        className="w-10"
      />
    </Link>
  );
}
