import type { ImageProps, StaticImageData } from 'next/image';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import { EmptyImage } from '@/assets';

const S3_URL = process.env.NEXT_PUBLIC_S3_BUCKET_URL;

export interface CustomImageProps extends Omit<ImageProps, 'src'> {
  src?: string;
  fallback?: string | StaticImageData;
  imageClassName?: string;
}

export default function CustomImage({
  src,
  fallback = EmptyImage,
  className,
  imageClassName,
  ...props
}: CustomImageProps) {
  const [image, setImage] = useState<string | StaticImageData>();

  useEffect(() => {
    if (!src) {
      setImage(fallback);
      return;
    }

    const img = `${src}`;
    if (img.startsWith('http') || img.startsWith('data')) {
      setImage(img);
    } else {
      setImage(`${S3_URL}/${img}`);
    }
  }, [fallback, src]);

  return (
    <div
      className={`relative overflow-hidden ${className} ${
        image ? '' : 'animate-skeleton'
      }`}
    >
      {image && (
        <Image
          {...props}
          src={image}
          alt={props.alt}
          className={imageClassName}
          fill={true}
          draggable={false}
          crossOrigin="anonymous"
          style={{ objectFit: 'cover', objectPosition: 'center' }}
          onError={() => {
            setImage(fallback);
          }}
        />
      )}
    </div>
  );
}
