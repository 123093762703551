import axios from 'axios';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';

import type {
  UserChangePassword,
  UserForgotPassword,
  UserOtpSession,
  UserOtpSessionCode,
  UserSignIn,
  UserSignUp,
  UserUpdateProfile,
} from '@/@types/common';

const signIn = (
  userSignIn: UserSignIn
): ReturnType<typeof EmailPassword.signIn> => {
  return axios.post('/api/auth/login', userSignIn).then((res) => res.data);
};

const signUp = (
  userSignUp: UserSignUp
): ReturnType<typeof EmailPassword.signUp> => {
  return axios.post('/api/auth/register', userSignUp).then((res) => res.data);
};

const signOut = (): ReturnType<typeof EmailPassword.signOut> => {
  return EmailPassword.signOut();
};

const changePassword = (userChangePassword: UserChangePassword) => {
  return axios
    .post('/api/auth/change-password', userChangePassword)
    .then((res) => res.data);
};

const updateProfile = (userUpdateProfile: UserUpdateProfile) => {
  const formData = new FormData();
  formData.append('fullname', userUpdateProfile.fullname);
  if (userUpdateProfile.avatar != null) {
    formData.append('avatar', userUpdateProfile.avatar);
  }

  return axios
    .post('/api/auth/update-profile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);
};

const forgotPassword = (userForgotPassword: UserForgotPassword) => {
  return axios
    .post('/api/auth/forgot-password', userForgotPassword)
    .then((res) => res.data);
};

const forgotPasswordConfirmOtp = (userOtpSessionCode: UserOtpSessionCode) => {
  return axios
    .post('/api/auth/confirm-forgot-password', userOtpSessionCode)
    .then((res) => res.data);
};

const confirmOtp = (
  userOtpSessionCode: UserOtpSessionCode
): ReturnType<typeof EmailPassword.signIn> => {
  return axios
    .post('/api/auth/confirm-otp', userOtpSessionCode)
    .then((res) => res.data);
};

const resendOtp = (userOtpSession: UserOtpSession) => {
  return axios
    .post('/api/auth/resend-otp', userOtpSession)
    .then((res) => res.data);
};

const userProfile = () => {
  return axios.get('/api/auth/user-profile').then((res) => res.data);
};

const CustomAuth = {
  confirmOtp,
  forgotPassword,
  forgotPasswordConfirmOtp,
  resendOtp,
  signIn,
  signOut,
  signUp,
  changePassword,
  updateProfile,
  userProfile,
};

export default CustomAuth;
