/* eslint-disable no-template-curly-in-string */
export const defaultValidateMessages = {
  default: 'Sai định dạng trường ${label}.',
  required: 'Vui lòng không để trống ${label}.',
  enum: '${label} phải nằm trong các giá trị [${enum}].',
  whitespace: 'Vui lòng không để trống ${label}.',
  date: {
    format: '${label} không đúng định dạng ngày.',
    parse: '${label} không đúng định dạng ngày.',
    invalid: '${label} không đúng định dạng ngày.',
  },
  types: {
    string: '${label} phải là chuỗi ký tự.',
    method: '${label} phải là ${type}.',
    array: '${label} phải là mảng.',
    object: '${label} phải là ${type}.',
    number: '${label} phải là số thực hoặc số nguyên.',
    date: '${label} phải là định dạng ngày.',
    boolean: '${label} phải là Có/Không.',
    integer: '${label} phải là số nguyên.',
    float: '${label} phải là số thực.',
    regexp: '${label} phải thỏa ${type}.',
    email: '${label} không đúng định dạng.',
    url: '${label} không đúng định dạng.',
    hex: '${label} không đúng định dạng.',
  },
  string: {
    len: '${label} phải có độ dài đúng ${len} ký tự.',
    min: '${label} phải có ít nhất ${min} ký tự.',
    max: '${label} chỉ được tối đa ${max} ký tự.',
    range: '${label} phải từ ${min} đến ${max} ký tự.',
  },
  number: {
    len: '${label} phải bằng đúng ${len}.',
    min: '${label} phải lớn hơn hoặc bằng ${min}.',
    max: '${label} phải bé hơn hoặc bằng ${max}.',
    range: '${label} phải từ ${min} đến ${max}.',
  },
  array: {
    len: '${label} phải có đúng ${len} phần tử.',
    min: '${label} phải có ít nhất ${min} phần tử.',
    max: '${label} chỉ được tối đa ${max} phần tử.',
    range: '${label} phải có từ ${min} đến ${max} phần tử.',
  },
  pattern: {
    mismatch: '${label} không đúng định dạng ${pattern}.',
  },
};
