import { AlertCircle, CheckCircle, XClose } from '@untitled-ui/icons-react';
import type { RefObject } from 'react';
import { createContext, useContext, useEffect } from 'react';

import type { INotiVariant } from '@/services/notiService';
import useNotiService from '@/services/notiService';

export const notiContext = createContext<RefObject<HTMLDivElement>>({
  current: null,
});

export const notiVariants: INotiVariant = {
  success: {
    icon: <CheckCircle className="text-green-500" />,
    title: 'Thành công',
    color: 'text-green-500',
  },
  error: {
    icon: <AlertCircle className="text-red-500" />,
    title: 'Đã có lỗi xảy ra',
    color: 'text-red-500',
  },
};

const Notification = () => {
  const { notiData, close } = useNotiService((state) => ({
    notiData: state.notiData,
    close: state.close,
  }));
  const refNotiContext = useContext(notiContext);

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (
        refNotiContext.current &&
        refNotiContext.current.contains(target as Node)
      ) {
        return;
      }
      close();
    };
    document.addEventListener('mousedown', clickHandler);

    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (keyCode !== 27) return;
      close();
    };
    document.addEventListener('keydown', keyHandler);

    return () => {
      document.removeEventListener('mousedown', clickHandler);
      document.removeEventListener('keydown', keyHandler);
    };
  }, [notiData, close]);

  return (
    notiData.isOpen && (
      <div
        id="toast-default"
        className="fixed right-5 top-5 z-[100] flex w-full max-w-xs rounded-lg bg-white p-4 shadow"
        role="alert"
        ref={refNotiContext}
      >
        <div className="inline-flex justify-center">
          {notiVariants[notiData.variant].icon}
          <span className="sr-only">Fire icon</span>
        </div>
        <div className="ml-3">
          <div className={`font-bold ${notiVariants[notiData.variant].color}`}>
            {notiVariants[notiData.variant].title}
          </div>
          <div className="text-sm font-normal text-typo-200">
            {notiData.message}
          </div>
        </div>
        <button
          type="button"
          className="-m-1.5 ms-auto inline-flex size-8 items-center justify-center rounded-lg bg-white p-1.5 text-typo-100 hover:bg-gray-100 hover:text-typo-300 focus:ring-2 focus:ring-gray-300 dark:text-gray-500"
          data-dismiss-target="#toast-default"
          aria-label="Close"
          onClick={() => close()}
        >
          <XClose className="text-typo-200" />
          <span className="sr-only">Close</span>
        </button>
      </div>
    )
  );
};

export default Notification;
