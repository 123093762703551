'use client';

import { Bell02, ChevronLeft } from '@untitled-ui/icons-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { EmptyAvatar } from '@/assets';
import { navigatorItems } from '@/config/constants';
import useUserService from '@/services/userService';

import CustomImage from '../common/CustomImage';
import IconButton from '../common/IconButton';
import HeaderLogo from './HeaderLogo';

const SignInSignUp = () => {
  const router = useRouter();
  const session = useSessionContext();
  const { currentUser } = useUserService((state) => ({
    currentUser: state.currentUser,
  }));

  return (
    <div className="flex flex-row items-center gap-5">
      {!session.loading && currentUser == null && (
        <>
          <IconButton
            className="!px-4 !py-2 !text-white"
            label="Đăng nhập"
            variant="secondaryTransparent"
            disableTranslate
            onClick={async () => {
              router.push('/login', undefined, { shallow: true });
            }}
          />
          <IconButton
            className="!px-4 !py-2"
            label="Đăng ký"
            disableTranslate
            onClick={() => {
              router.push('/register', undefined, { shallow: true });
            }}
          />
        </>
      )}
      {!session.loading && currentUser != null && (
        <>
          <Link href="/notifications">
            <div className="hidden p-2 text-white md:block">
              <Bell02 />
            </div>
          </Link>
          <Link href="/user" shallow>
            <CustomImage
              src={
                currentUser.avatar != null
                  ? `${currentUser.avatar}?width=48`
                  : undefined
              }
              alt={'UserAvatar'}
              sizes="100"
              className="size-10 rounded-full"
              fallback={EmptyAvatar}
            />
          </Link>
        </>
      )}
    </div>
  );
};

export default function Header() {
  const router = useRouter();
  const routeSegments =
    router.asPath
      .split('?')[0]
      ?.split('/')
      .filter((seg) => seg) ?? [];

  return (
    <header className="sticky inset-x-0 top-0 z-40 box-border border-b-2 bg-header">
      <div className="relative mx-auto px-5 py-3 md:container xl:max-w-screen-xl">
        <nav className="flex min-h-10 flex-row justify-between text-primary-500">
          <div className="flex flex-row items-center justify-center gap-x-10">
            <Link
              href={`/${routeSegments
                .slice(0, routeSegments.length - 1)
                .join('/')}`}
              className={`py-1.5 pr-2 md:hidden ${
                routeSegments.length > 1 ? 'block' : 'hidden'
              }`}
              shallow
            >
              <ChevronLeft />
            </Link>
            <div
              className={`md:block ${routeSegments.length > 1 ? 'hidden' : ''}`}
            >
              <HeaderLogo />
            </div>
            <div className="hidden items-center gap-8 md:flex">
              {navigatorItems
                .filter((item) => item.showHeader)
                .map((item, i) => (
                  <Link
                    key={i}
                    href={item.path}
                    className="py-3.5 font-semibold text-white"
                    shallow
                  >
                    {item.headerName ? item.headerName : item.name}
                  </Link>
                ))}
            </div>
          </div>
          <SignInSignUp />
        </nav>
      </div>
    </header>
  );
}
