import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { WinlandLogo } from '@/assets';

export default function FooterLogo() {
  return (
    <Link
      href="/"
      scroll={false}
      className="flex flex-row items-center text-inherit"
      shallow
    >
      <Image
        src={WinlandLogo}
        alt="logo"
        className="h-10 w-auto xl:h-12"
        width={48}
        height={48}
        crossOrigin="anonymous"
      />
      <span className="ml-4 text-base font-bold sm:text-xl lg:text-2xl">
        Winland
      </span>
    </Link>
  );
}
