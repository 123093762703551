import { SearchLg } from '@untitled-ui/icons-react';
import moment from 'moment';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import type { FilterReservationInput } from '@/@types/common';
import type { Apartment, Reservation } from '@/backend/directusSchema';
import { reservationStatus } from '@/config/status';
import useDebounce from '@/services/debounce';
import ReservationApi from '@/services/reservationApi';

import Badge from '../common/Badge';
import DateRangePicker from '../common/DateRangePicker';
import EmptyData from '../common/EmptyData';
import Pagination from '../common/Pagination';
import SkeletonText from '../common/SkeletonText';
import InputField from '../form/InputField';

const mockData = [{}, {}, {}, {}, {}];

const ReservationHistory = () => {
  const [filter, setFilter] = useState<FilterReservationInput>({
    page: 0,
    size: 5,
  });
  const [apartmentCode, setApartmentCode] = useState<string>();
  const [reservations, setReservations] = useState<Reservation[]>();
  const debouncedSearchTerm = useDebounce(apartmentCode, 500);

  useEffect(() => {
    if (reservations == null) {
      ReservationApi.filterReservations(filter).then((res) =>
        setReservations(res)
      );
    }
  }, [reservations]);

  useEffect(() => {
    setFilter((oldFilter) => ({ ...oldFilter, apartmentCode, page: 0 }));
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setReservations(undefined);
  }, [filter]);

  return (
    <div className="flex flex-col gap-6 pb-20">
      <div className="flex flex-col justify-between gap-2 lg:flex-row lg:items-center lg:gap-0">
        <span className="font-semibold sm:text-lg">Danh sách giữ chỗ</span>
        <div className="flex flex-row items-center gap-2">
          <InputField
            placeholder="Nhập mã căn hộ"
            icon={<SearchLg />}
            value={apartmentCode}
            onChange={(event) => {
              setApartmentCode(event.target.value);
            }}
            className="border"
          />
          <DateRangePicker
            selected={
              filter.fromDate &&
              filter.toDate && {
                from: filter.fromDate,
                to: filter.toDate,
              }
            }
            onDateRangeChange={(newDateRange) => {
              setFilter({
                ...filter,
                fromDate: new Date(
                  moment(newDateRange.from)
                    .set({
                      hour: 0,
                      minute: 0,
                      second: 0,
                      millisecond: 0,
                    })
                    .toISOString()
                ),
                toDate: new Date(
                  moment(newDateRange.to)
                    .set({
                      hour: 23,
                      minute: 59,
                      second: 59,
                      millisecond: 999,
                    })
                    .toISOString()
                ),
              });
            }}
          />
        </div>
      </div>
      {reservations != null && reservations.length === 0 && (
        <EmptyData
          className="col-span-full flex items-center justify-center py-10"
          title=""
          subtitle="Bạn chưa có dữ liệu giữ chỗ nào"
        />
      )}
      <div className="flex w-full flex-col">
        <ul>
          {(reservations ?? (mockData as Reservation[])).map((item, i) => (
            <div
              key={i}
              className="flex flex-col justify-between border-t py-6 md:flex-row"
            >
              <div className="flex flex-col gap-1 text-sm">
                <div className="flex flex-row items-center gap-2">
                  <SkeletonText
                    className="text-typo-100"
                    loading={item?.id == null}
                    placeholder="Mã phiếu giữ chỗ: RV-50"
                  >
                    Mã phiếu giữ chỗ: RV-{item?.id}
                  </SkeletonText>

                  <SkeletonText
                    loading={item.id == null}
                    placeholder="Đã giữ chỗ"
                  >
                    <Badge
                      text={reservationStatus[item?.status]?.content || ''}
                      variant={
                        reservationStatus[item?.status]?.badgeVariant ||
                        'roundedSuccess'
                      }
                    />
                  </SkeletonText>
                </div>

                <SkeletonText
                  className="font-medium text-typo-200"
                  loading={item?.id == null}
                  placeholder="Căn hộ BT1-50"
                >
                  Căn hộ {(item?.apartment_id as Apartment)?.code}
                </SkeletonText>

                <SkeletonText
                  className="text-typo-100"
                  loading={item?.id == null}
                  placeholder="09/05/2024 10:05"
                >
                  {moment(item?.created_at).format('DD/MM/YYYY HH:mm')}
                </SkeletonText>
              </div>

              <div className="flex self-end md:self-start">
                <SkeletonText
                  loading={item?.id == null}
                  placeholder="Xem chi tiết"
                >
                  <Link
                    className="font-semibold text-primary-700 hover:text-primary-500"
                    href={`/user/reservations/${item?.id}`}
                    shallow
                  >
                    Xem chi tiết
                  </Link>
                </SkeletonText>
              </div>
            </div>
          ))}
        </ul>
        <Pagination
          currentPage={filter.page}
          currentSize={reservations?.length ?? 0}
          maxSize={5}
          onPage={(nextPage) => {
            setFilter({ ...filter, page: nextPage });
          }}
        />
      </div>
    </div>
  );
};

export default ReservationHistory;
