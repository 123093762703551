import BookingIcon from '@/public/assets/images/booking-icon.svg';
import CanhanIcon from '@/public/assets/images/canhan-icon.svg';
import CheckedImage from '@/public/assets/images/checked.svg';
import MasterPlanImage from '@/public/assets/images/chia-lo.png';
import DepositIcon from '@/public/assets/images/deposit-icon.svg';
import EmptyImage from '@/public/assets/images/empty.webp';
import EmptyAddressImage from '@/public/assets/images/empty-address.svg';
import EmptyAvatar from '@/public/assets/images/empty-avatar.jpg';
import EmptyCartImage from '@/public/assets/images/empty-cart.svg';
import EmptyDefault from '@/public/assets/images/empty-default.svg';
import EmptyMenuImage from '@/public/assets/images/empty-menu.svg';
import EmptyOrder from '@/public/assets/images/empty-order.svg';
import EmptyTransaction from '@/public/assets/images/empty-transaction.svg';
import GiohangIcon from '@/public/assets/images/giohang-icon.svg';
import LoadingImage from '@/public/assets/images/loading.json';
import LogoImage from '@/public/assets/images/logo.png';
import MarkerImage from '@/public/assets/images/marker.svg';
import MoneyIcon from '@/public/assets/images/money.svg';
import QuyhoachIcon from '@/public/assets/images/quyhoach-icon.svg';
import ThongbaoIcon from '@/public/assets/images/thongbao-icon.svg';
import TrangchuIcon from '@/public/assets/images/trangchu-icon.svg';
import WalletImage from '@/public/assets/images/wallet.svg';
import WinlandHero from '@/public/assets/images/winland-hero.png';
import WinlandLogo from '@/public/assets/images/winland-logo.png';

export {
  BookingIcon,
  CanhanIcon,
  CheckedImage,
  DepositIcon,
  EmptyAddressImage,
  EmptyAvatar,
  EmptyCartImage,
  EmptyDefault,
  EmptyImage,
  EmptyMenuImage,
  EmptyOrder,
  EmptyTransaction,
  GiohangIcon,
  LoadingImage,
  LogoImage,
  MarkerImage,
  MasterPlanImage,
  MoneyIcon,
  QuyhoachIcon,
  ThongbaoIcon,
  TrangchuIcon,
  WalletImage,
  WinlandHero,
  WinlandLogo,
};
