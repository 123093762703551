'use client';

import { Calendar } from '@untitled-ui/icons-react';
import Flatpickr from 'react-flatpickr';

import { customFlatpickrLocale } from '@/utils/customFlatpickrLocale';
import { getDateRangeLabel } from '@/utils/Parsers';

import IconButton from './IconButton';

const CustomInput = ({ selected, inputRef, ...props }: any) => {
  return (
    <>
      <IconButton
        icon={<Calendar />}
        label={getDateRangeLabel(selected?.from, selected?.to)}
        disableTranslate
        type="button"
        variant="secondary"
        className="!py-2"
        ref={inputRef}
        {...props}
      />
    </>
  );
};

export interface DateRange {
  from: Date;
  to: Date;
}

export interface DateRangePickerProps {
  selected?: DateRange;
  onDateRangeChange: (dateRange: DateRange) => void;
}

const DateRangePicker = ({
  selected,
  onDateRangeChange,
}: DateRangePickerProps) => {
  return (
    <Flatpickr
      value={selected != null ? [selected.from, selected.to] : undefined}
      options={{
        mode: 'range',
        allowInput: false,
        disableMobile: true,
        locale: customFlatpickrLocale,
        monthSelectorType: 'static',
        dateFormat: 'd/m/Y',
        prevArrow:
          '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
        nextArrow:
          '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      }}
      onChange={([from, to]) => {
        if (from != null && to != null) {
          onDateRangeChange({ from, to });
        }
      }}
      render={({ ...props }, ref) => {
        return <CustomInput {...props} selected={selected} inputRef={ref} />;
      }}
    />
  );
};

export default DateRangePicker;
