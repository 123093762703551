import type { BadgeVariant } from '@/components/common/Badge';

export const bookingStatus: {
  [key: string]: {
    content: string;
    backgroundColor: string;
    badgeVariant: BadgeVariant;
  };
} = {
  A: {
    content: 'Đang Booking',
    backgroundColor: 'bg-warning-200',
    badgeVariant: 'roundedWarning',
  },
  D: {
    content: 'Hoàn thành',
    backgroundColor: 'bg-success-300',
    badgeVariant: 'roundedSuccess',
  },
};

export const depositStatus: {
  [key: string]: {
    content: string;
    backgroundColor: string;
    badgeVariant: BadgeVariant;
  };
} = {
  A: {
    content: 'Đã cọc',
    backgroundColor: 'bg-success-200',
    badgeVariant: 'roundedSuccess',
  },
  C: {
    content: 'Đã hủy',
    backgroundColor: 'bg-warning-300',
    badgeVariant: 'roundedWarning',
  },
};

export const reservationStatus: {
  [key: string]: {
    content: string;
    backgroundColor: string;
    badgeVariant: BadgeVariant;
  };
} = {
  A: {
    content: 'Đã giữ chỗ',
    backgroundColor: 'bg-success-200',
    badgeVariant: 'roundedSuccess',
  },
  C: {
    content: 'Đã hủy',
    backgroundColor: 'bg-warning-300',
    badgeVariant: 'roundedWarning',
  },
};

export const apartmentStatus: {
  [key: string]: {
    content: string;
    backgroundColor: string;
    fillColor: string;
    badgeVariant: BadgeVariant;
  };
} = {
  A: {
    content: 'Đang mở bán',
    backgroundColor: 'bg-success-500',
    fillColor:
      'fill-success-500/30 hover:fill-success-500/50 focus:fill-success-500/50',
    badgeVariant: 'roundedSuccess',
  },
  R: {
    content: 'Đang nhận đặt chỗ ',
    backgroundColor: 'bg-warning-300',
    fillColor:
      'fill-warning-300/30 hover:fill-warning-300/50 focus:fill-warning-530000/50',
    badgeVariant: 'roundedWarning',
  },
  D: {
    content: 'Đã bán',
    backgroundColor: 'bg-error-500',
    fillColor:
      'fill-error-500/30 hover:fill-error-500/50 focus:fill-error-500/50',
    badgeVariant: 'roundedError',
  },
  B: {
    content: 'Đang được Booking',
    backgroundColor: 'bg-denim-600',
    fillColor:
      'fill-denim-600/30 hover:fill-denim-600/50 focus:fill-denim-600/50',
    badgeVariant: 'roundedDenim',
  },
};
