import { ArrowLeft, ArrowRight } from '@untitled-ui/icons-react';
import { type HTMLProps } from 'react';

export interface PaginationProps extends HTMLProps<HTMLDivElement> {
  currentPage?: number;
  currentSize?: number;
  maxPage?: number;
  maxSize?: number;
  onPage?: (page: number) => void;
}

const Pagination = ({
  currentPage = 0,
  currentSize = 0,
  maxPage,
  maxSize,
  onPage = () => undefined,
}: PaginationProps) => {
  return (
    <div className="flex w-full justify-between">
      <div
        className="flex flex-row items-center gap-2 text-typo-100 transition-colors hover:cursor-pointer hover:text-primary-600"
        onClick={() => onPage(currentPage - 1)}
      >
        {currentPage > 0 && (
          <>
            <ArrowLeft />
            <span className="hidden text-sm font-semibold sm:block">
              Trang trước
            </span>
          </>
        )}
      </div>
      <span className="relative flex size-10 items-center justify-center text-sm font-medium text-gray-700 before:absolute before:z-[-1] before:size-full before:rounded-full before:bg-gray-50">
        {currentPage + 1}
      </span>
      <div
        className="flex flex-row items-center gap-2 text-typo-100 transition-colors hover:cursor-pointer hover:text-primary-600"
        onClick={() => onPage(currentPage + 1)}
      >
        {((maxPage == null && maxSize != null && currentSize === maxSize) ||
          (maxPage != null && currentPage < maxPage)) && (
          <>
            <span className="hidden text-sm font-semibold sm:block">
              Trang sau
            </span>
            <ArrowRight />
          </>
        )}
      </div>
    </div>
  );
};

export default Pagination;
