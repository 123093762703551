import type { StaticImageData } from 'next/image';
import Image from 'next/image';
import type { HTMLProps } from 'react';
import React from 'react';

import { EmptyDefault } from '@/assets';

export interface EmptyDataProps extends HTMLProps<HTMLDivElement> {
  title?: string;
  subtitle?: string;
  image?: StaticImageData;
}

export default function EmptyData({
  title = 'Không tìm thấy dữ liệu',
  subtitle = 'Không tìm thấy dữ liệu bạn cần tìm',
  image = EmptyDefault,
  className,
}: EmptyDataProps) {
  return (
    <div
      className={`z-[-1] mx-auto flex max-w-sm flex-col items-center justify-center text-xl font-medium text-primary-500 ${className}`}
    >
      {image && (
        <div className="relative size-32">
          <Image
            src={image}
            alt=""
            fill={true}
            draggable={false}
            sizes="128px"
            crossOrigin="anonymous"
            style={{ objectFit: 'contain', objectPosition: 'center' }}
          />
        </div>
      )}
      {title && (
        <span className="mt-5 px-5 text-center font-semibold text-typo-300 sm:text-lg">
          {title}
        </span>
      )}
      {subtitle && (
        <span className="mt-2 px-5 text-center text-sm font-normal text-typo-100  sm:text-base">
          {subtitle}
        </span>
      )}
    </div>
  );
}
