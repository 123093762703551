import React from 'react';

import FooterLogo from './FooterLogo';

export default function Footer() {
  return (
    <footer className="bg-gray-800 pb-24 text-white md:pb-0">
      <div className="border-t-2">
        <div className="section gap-5 px-5 pb-14 pt-28">
          <div className="flex w-full flex-col-reverse gap-x-10 gap-y-5 md:flex-row md:justify-between">
            <div className="flex flex-col gap-3 text-xs sm:text-sm">
              <FooterLogo />
              <span className="text-start text-gray-400">
                ©2023 winland.rizerssoft.app. All Rights Reserved
              </span>
              <span className="text-start text-gray-400">
                <a
                  href="https://www.rizerssoft.com/"
                  aria-label="Powered by Rizerssoft Ltd"
                  className="underline"
                  target="_blank"
                >
                  Powered by Rizerssoft Ltd
                </a>
              </span>
            </div>
            <div className="flex flex-col gap-3 text-xs sm:text-sm">
              <span className="text-start text-gray-400">
                CÔNG TY TNHH GIẢI PHÁP PHẦN MỀM RIZERS - SOFT
              </span>
              <span className="text-start text-gray-400">
                <b>Địa chỉ: </b>72/19/8/29 Đường số 4, khu phố 6 , Phường Hiệp
                Bình Phước, Thành phố Thủ Đức, Thành phố Hồ Chí Minh, Việt Nam
              </span>
              <span className="text-start text-gray-400">
                <b>Hotline hỗ trợ khách hàng: </b>0374 828 046
              </span>
              <span className="text-start text-gray-400">
                <b>Email hỗ trợ kỹ thuật: </b>support@rizerssoft.com
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
