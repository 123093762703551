import OneSignal from 'react-onesignal';

export const ONESIGNAL_APPID: string =
  process.env.NEXT_PUBLIC_ONESIGNAL_APPID ||
  '895425f0-c9cb-4a03-a203-4b7bf8a61a87';

let init = false;

export default async function runOneSignal() {
  if (init) return;
  init = true;
  OneSignal.init({
    appId: ONESIGNAL_APPID,
    allowLocalhostAsSecureOrigin: true,
    autoResubscribe: true,
    autoRegister: true,
    persistNotification: true,
    serviceWorkerPath: '/onesignal/OneSignalSDKWorker.js',
    serviceWorkerParam: {
      scope: '/onesignal/winland',
    },
  });
}
