import type { HTMLProps } from 'react';
import React from 'react';

export interface SkeletonTextProps extends HTMLProps<HTMLSpanElement> {
  loading?: boolean;
  placeholder?: string;
  skeletonClassName?: string;
}

export default function SkeletonText({
  loading = false,
  placeholder = 'Không xác định',
  skeletonClassName,
  className,
  children,
}: SkeletonTextProps) {
  return (
    <span
      className={`${className} ${
        loading
          ? `w-fit animate-skeleton rounded-full bg-secondary-200 !text-transparent ${skeletonClassName}`
          : ''
      }`}
    >
      {loading ? placeholder : children}
    </span>
  );
}
