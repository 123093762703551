import axios from 'axios';

import type { BookingInput, FilterBookingInput } from '@/@types/common';

const filterBookings = (input: FilterBookingInput) => {
  return axios
    .get('/api/bookings', {
      params: {
        apartmentCode: input.apartmentCode,
        fromDate: input.fromDate,
        toDate: input.toDate,
        page: input.page,
        size: input.size,
      },
    })
    .then((res) => res.data);
};

const createBooking = (input: BookingInput) => {
  return axios.post('/api/bookings/create', input).then((res) => res.data);
};

const getBookingById = (bookingId: string) => {
  return axios.get(`/api/bookings/${bookingId}`).then((res) => res.data);
};

const completeBooking = (bookingId: string) => {
  return axios
    .post(`/api/bookings/${bookingId}/complete`)
    .then((res) => res.data);
};

const BookingApi = {
  createBooking,
  filterBookings,
  getBookingById,
  completeBooking,
};

export default BookingApi;
